import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

type Redirect = {
  condition?: (to: RouteLocationNormalized) => boolean
  from: string
  to: (to: RouteLocationNormalized) => RouteLocationRaw
}

const redirects: Redirect[] = [
  {
    // redirect all account pages with redeem query param to home
    condition: (to) => 'redeem' in to.query,
    from: '/account',
    to: (to) => ({ path: '/', query: to.query }),
  },
]

export default defineNuxtRouteMiddleware((to) => {
  const redirect = redirects.find(
    (r) => r.from === to.path && (!r.condition || r.condition(to)),
  )

  if (redirect) {
    return navigateTo(redirect.to(to))
  }
})
